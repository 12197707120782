import Backpack from './img/backpack.png'

export default function Hero() {
  return (
    <section
      className="flex w-full pt-12 mx-auto bg-right bg-cover md:pt-0 md:items-center"
      style={{maxWidth:'1600px',height: '32rem', backgroundColor: '#e2eaf0', backgroundImage: `url('${Backpack}')`, backgroundPosition: '90% 50%', backgroundSize: '30%', backgroundRepeat: 'no-repeat'}}
    >
      <div className="container mx-auto">
        <div className="flex flex-col items-start justify-center w-full px-6 tracking-wide lg:w-1/2">
          <h1 className="my-4 text-2xl text-black">
            Just launched: <strong>Backpack</strong>
          </h1>
          <p>Limited Edition 2022</p>
         
        </div>
      </div>
    </section>
  );
}
