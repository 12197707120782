import Nav from './Nav';
import Hero from './Hero';
import Products from './Products';
import Footer from './Footer';

export default function App() {
  return (
    <>
      <Nav />
      <Hero />
      <Products />
      <Footer />
    </>
  )
}
