import axios from 'axios';

const URL = 'https://20v0zo57b1.execute-api.us-east-2.amazonaws.com';

const Inventory = {
    Get: async () => {
        return axios.get(`${URL}/inventory`);
    }
};

const API = { Inventory };

export default API;