export default function Footer() {
  return (
    <footer className="container py-8 mx-auto bg-white border-t border-gray-400">
      <div className="container flex px-3 py-8 ">
        <div className="flex flex-wrap w-full mx-auto">
          <div className="flex w-full lg:w-1/2 ">
            <div className="px-3 md:px-0">
              <h3 className="font-bold text-gray-900">About</h3>
              <p className='py-4'>
                This application is a demo application for <a href='https://squaredup.com' target='_blank' rel='noreferrer noopener'>SquaredUp</a>.
                <br />
                The products are (sadly) not real, please do not buy them.
              </p>
            </div>
          </div>         
        </div>
      </div>
    </footer>
  );
}
